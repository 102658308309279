<template lang="html">
  <article class="charger" @click="connectorClicked">
    <div class="charger__left" :class="classType">
      <span class="charger__letter">
        {{ connector.label }}
      </span>
      <img
        class="charger__icon"
        :src="`${config.imagesPath}/charger-${connectorTypeSlug}.svg`"
        alt=""
      />
    </div>
    <h3 class="charger__type">
      {{ connectorTypeName }}
    </h3>
    <div class="charger__info">
      <img
        v-if="connector.mode === 'DC'"
        class="charger__info-icon"
        src="@/assets/images/charger-dc-icon.svg"
        alt=""
      />
      <img
        v-else
        class="charger__info-icon"
        src="@/assets/images/charger-ac-icon.svg"
        alt=""
      />
      <span class="charger__info-text">
        {{ connector.mode === "DC" ? "DC" : "AC" }} | {{ connector.voltage }}V |
        {{ connector.current }}A
      </span>
    </div>
    <div
      v-if="connector.connectorFormat === 'CABLE'"
      class="charger__has-cable"
    >
      <picture class="charger__has-cable-icon">
        <source
          srcset="@/assets/images/charger-has-cable-dark.svg"
          media="(prefers-color-scheme: dark)"
        />
        <img src="@/assets/images/charger-has-cable.svg" alt="" />
      </picture>
      <span class="charger__has-cable-text">
        {{ $t("chargingPage.connectorLayout.hasCable") }}
      </span>
    </div>
    <div class="charger__price">
      {{ price }}
    </div>
    <div class="charger__power">
      <img
        class="charger__power-icon"
        src="@/assets/images/charger-power.svg"
        alt=""
      />
      <span class="charger__power-text">{{ connector.power / 1000 }} kW</span>
    </div>
  </article>
</template>

<script>
import { PriceService } from "@/services/price-service"
import config from "../../config"
import { chargerTypes, chargerTypesFromBackend } from "../../models/models"

export default {
  name: "ConnectorComponent",
  props: {
    connector: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chargerTypes,
      chargerTypesFromBackend,
      config: config
    }
  },
  computed: {
    classType() {
      return this.connector.status === "AVAILABLE"
        ? "charger__left--available"
        : "charger__left--not-available"
    },
    price() {
      return PriceService.getPriceLabel(this.connector.price, this.$i18n)
    },
    connectorTypeName() {
      return this.chargerTypes[this.connectorTypeSlug]
        ? this.chargerTypes[this.connectorTypeSlug].name
        : this.chargerTypes.type2.name
    },
    connectorTypeSlug() {
      return (
        (this.connector &&
          this.chargerTypesFromBackend[this.connector.connectorType]) ||
        "type2"
      )
    }
  },
  methods: {
    connectorClicked() {
      if (this.connector.status === "AVAILABLE") {
        this.$emit("clicked", this.connector)
      }
    }
  }
}
</script>
