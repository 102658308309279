<template>
  <div class="registration">
    <page-header />

    <main class="terms page-content">
      <h1 class="page-title">
        {{ $t("registrationPage.title") }}
      </h1>
      <p class="text">
        {{ $t("registrationPage.subTitle") }}
      </p>
      <form>
        <label class="radbox">
          <input
            v-model="isPerson"
            :value="true"
            class="radbox__input"
            type="radio"
          />
          <span class="radbox__text">{{
            $t("registrationPage.form.person")
          }}</span>
        </label>
        <label class="radbox">
          <input
            v-model="isPerson"
            :value="false"
            class="radbox__input"
            type="radio"
          />
          <span class="radbox__text">{{
            $t("registrationPage.form.legalPerson")
          }}</span>
        </label>
        <hr class="form-separator" />
        <template v-if="!isPerson">
          <input
            v-if="config.registrationFields.companyName"
            v-model="$v.form.companyName.$model"
            class="input"
            :class="{ error: $v.form.companyName.$anyError }"
            type="text"
            :placeholder="$t('registrationPage.form.companyName') + '*'"
          />
          <p v-if="$v.form.companyName.$anyError" class="input-error">
            {{
              $t("registrationPage.validation.companyName", {
                minLength: formValidationParams.companyName.minLength,
                maxLength: formValidationParams.companyName.maxLength
              })
            }}
          </p>
          <input
            v-if="config.registrationFields.taxNumber"
            v-model="$v.form.taxNumber.$model"
            class="input"
            :class="{ error: $v.form.taxNumber.$anyError }"
            type="text"
            :placeholder="$t('registrationPage.form.taxNumber') + '*'"
          />
          <p v-if="$v.form.taxNumber.$anyError" class="input-error">
            {{
              $t("registrationPage.validation.taxNumber", {
                minLength: formValidationParams.taxNumber.minLength,
                maxLength: formValidationParams.taxNumber.maxLength
              })
            }}
          </p>
          <input
            v-if="config.registrationFields.groupCode"
            v-model="$v.form.groupCode.$model"
            class="input"
            :class="{ error: $v.form.groupCode.$anyError }"
            type="text"
            :placeholder="$t('registrationPage.form.groupCode')"
          />
          <p v-if="$v.form.groupCode.$anyError" class="input-error">
            {{
              $t("registrationPage.validation.groupCode", {
                minLength: formValidationParams.groupCode.minLength,
                maxLength: formValidationParams.groupCode.maxLength
              })
            }}
          </p>
        </template>
        <template v-else>
          <input
            v-if="config.registrationFields.lastName"
            v-model="$v.form.lastName.$model"
            class="input"
            :class="{ error: $v.form.lastName.$anyError }"
            type="text"
            :placeholder="$t('registrationPage.form.lastName') + '*'"
          />
          <p v-if="$v.form.lastName.$anyError" class="input-error">
            {{
              $t("registrationPage.validation.lastName", {
                minLength: formValidationParams.lastName.minLength,
                maxLength: formValidationParams.lastName.maxLength
              })
            }}
          </p>
          <input
            v-if="config.registrationFields.firstName"
            v-model="$v.form.firstName.$model"
            class="input"
            :class="{ error: $v.form.firstName.$anyError }"
            type="text"
            :placeholder="$t('registrationPage.form.firstName') + '*'"
          />
          <p v-if="$v.form.firstName.$anyError" class="input-error">
            {{
              $t("registrationPage.validation.firstName", {
                minLength: formValidationParams.firstName.minLength,
                maxLength: formValidationParams.firstName.maxLength
              })
            }}
          </p>
          <input
            v-if="config.registrationFields.phoneNumber"
            v-model="$v.form.phoneNumber.$model"
            class="input"
            :class="{ error: $v.form.phoneNumber.$anyError }"
            type="text"
            :placeholder="$t('registrationPage.form.phoneNumber') + '*'"
          />
          <p v-if="$v.form.phoneNumber.$anyError" class="input-error">
            {{
              $t("registrationPage.validation.phoneNumber", {
                minLength: formValidationParams.phoneNumber.minLength,
                maxLength: formValidationParams.phoneNumber.maxLength
              })
            }}
          </p>
        </template>
        <hr class="form-separator" />
        <input
          v-if="config.registrationFields.country"
          v-model="$v.form.country.$model"
          class="input"
          :class="{ error: $v.form.country.$anyError }"
          type="text"
          :placeholder="$t('registrationPage.form.country') + '*'"
        />
        <p v-if="$v.form.country.$anyError" class="input-error">
          {{
            $t("registrationPage.validation.country", {
              minLength: formValidationParams.country.minLength,
              maxLength: formValidationParams.country.maxLength
            })
          }}
        </p>
        <input
          v-if="config.registrationFields.postCode"
          v-model="$v.form.postCode.$model"
          class="input"
          :class="{ error: $v.form.postCode.$anyError }"
          type="text"
          :placeholder="$t('registrationPage.form.postCode') + '*'"
        />
        <p v-if="$v.form.postCode.$anyError" class="input-error">
          {{
            $t("registrationPage.validation.postCode", {
              minLength: formValidationParams.postCode.minLength,
              maxLength: formValidationParams.postCode.maxLength
            })
          }}
        </p>
        <input
          v-if="config.registrationFields.city"
          v-model="$v.form.city.$model"
          class="input"
          :class="{ error: $v.form.city.$anyError }"
          type="text"
          :placeholder="$t('registrationPage.form.city') + '*'"
        />
        <p v-if="$v.form.city.$anyError" class="input-error">
          {{
            $t("registrationPage.validation.city", {
              minLength: formValidationParams.city.minLength,
              maxLength: formValidationParams.city.maxLength
            })
          }}
        </p>
        <input
          v-if="config.registrationFields.address"
          v-model="$v.form.address.$model"
          class="input"
          :class="{ error: $v.form.address.$anyError }"
          type="text"
          :placeholder="$t('registrationPage.form.address') + '*'"
        />
        <p v-if="$v.form.address.$anyError" class="input-error">
          {{
            $t("registrationPage.validation.address", {
              minLength: formValidationParams.address.minLength,
              maxLength: formValidationParams.address.maxLength
            })
          }}
        </p>
        <button-component
          block
          primary
          :disabled="$v.form.$invalid"
          :loading="submitIsLoading"
          @click.native="onSubmit"
        >
          {{ $t("registrationPage.enterBankCard") }}
        </button-component>
      </form>
    </main>
    <feedback-modal
      v-model="isFeedbackModalActive"
      :main-title="$t('errors.defaultError.mainTitle')"
      :sub-title="feedbackSubTitle"
      :success="false"
    />
  </div>
</template>

<script>
import FeedbackModal from "@/components/ui/FeedbackModal"
import { GuestChargingService } from "@/services/guest-charging-service"
import { stringNullify } from "@/services/Util"
import { validationMixin } from "vuelidate"
import {
  maxLength,
  minLength,
  required,
  requiredIf,
  helpers
} from "vuelidate/lib/validators"
import config from "../config"
import PageHeader from "../components/ui/PageHeader"
import ButtonComponent from "../components/ui/ButtonComponent"

// also has min-max length validation on formValidationParams
const TWO_TO_SIXTY_LETTERS_WITHOUT_DIGITS_AND_SOME_SPECIAL_CHARACTERS =
  helpers.regex(
    "TWO_TO_SIXTY_LETTERS_WITHOUT_DIGITS_AND_SOME_SPECIAL_CHARACTERS",
    /^[^<>$%";*+?~:()=0-9]{2,60}$/i
  )
const TWO_TO_SIXTY_LETTERS_WITHOUT_SOME_SPECIAL_CHARACTERS = helpers.regex(
  "TWO_TO_SIXTY_LETTERS_WITHOUT_SOME_SPECIAL_CHARACTERS",
  /^[^<>$%";*+?~:=]{2,60}$/i
)
const FOUR_TO_TWELVE_LETTERS_OR_DIGITS_OR_HYPHENS = helpers.regex(
  "FOUR_TO_TWELVE_LETTERS_OR_DIGITS_OR_HYPHENS",
  /^[a-zA-Z0-9-]{4,12}$/i
)
const FIVE_TO_150_LETTERS_WITHOUT_SOME_SPECIAL_CHARACTERS = helpers.regex(
  "FIVE_TO_150_LETTERS_WITHOUT_SOME_SPECIAL_CHARACTERS",
  /^[^<>$%";*+?~:=]{5,150}$/i
)
const FIVE_TO_TWENTY_LETTERS_OR_DIGITS_OR_HYPHENS = helpers.regex(
  "FIVE_TO_TWENTY_LETTERS_OR_DIGITS_OR_HYPHENS",
  /^[a-zA-Z0-9-]{5,20}$/i
)
const FIVE_TO_TWENTY_DIGITS = helpers.regex(
  "FIVE_TO_TWENTY_DIGITS",
  /^[0-9]{5,20}$/i
)
const FIVE_TO_TWENTY_DIGITS_WITH_OPTIONAL_PLUS_SIGN_AT_THE_FRONT =
  helpers.regex(
    "FIVE_TO_TWENTY_DIGITS_WITH_OPTIONAL_PLUS_SIGN_AT_THE_FRONT",
    /^\+?[0-9- ]{4,15}$/i
  )

const formValidationParams = {
  firstName: {
    minLength: 2,
    maxLength: 60
  },
  lastName: {
    minLength: 2,
    maxLength: 60
  },
  address: {
    minLength: 2,
    maxLength: 60
  },
  city: {
    minLength: 2,
    maxLength: 60
  },
  postCode: {
    minLength: 4,
    maxLength: 12
  },
  country: {
    minLength: 2,
    maxLength: 60
  },
  companyName: {
    minLength: 5,
    maxLength: 150
  },
  taxNumber: {
    minLength: 5,
    maxLength: 20
  },
  groupCode: {
    minLength: 5,
    maxLength: 20
  },
  phoneNumber: {
    minLength: 4,
    maxLength: 15
  }
}

export default {
  name: "RegistrationPage",
  components: {
    PageHeader,
    "feedback-modal": FeedbackModal,
    ButtonComponent
  },
  mixins: [validationMixin],
  props: {},
  data: function () {
    return {
      isPerson: true,
      form: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        postCode: "",
        country: "",
        companyName: "",
        taxNumber: "",
        groupCode: "",
        phoneNumber: ""
      },
      feedbackSubTitle: this.$i18n.t("errors.defaultError.subTitle"),
      config: config,
      isFeedbackModalActive: false,
      submitIsLoading: false,
      formValidationParams
    }
  },
  validations: {
    form: {
      firstName: {
        TWO_TO_SIXTY_LETTERS_WITHOUT_DIGITS_AND_SOME_SPECIAL_CHARACTERS,
        requiredIf: requiredIf(function () {
          return this.isPerson
        }),
        minLength: minLength(formValidationParams.firstName.minLength),
        maxLength: maxLength(formValidationParams.firstName.maxLength)
      },
      lastName: {
        TWO_TO_SIXTY_LETTERS_WITHOUT_DIGITS_AND_SOME_SPECIAL_CHARACTERS,
        requiredIf: requiredIf(function () {
          return this.isPerson
        }),
        minLength: minLength(formValidationParams.lastName.minLength),
        maxLength: maxLength(formValidationParams.lastName.maxLength)
      },
      address: {
        required,
        TWO_TO_SIXTY_LETTERS_WITHOUT_SOME_SPECIAL_CHARACTERS,
        minLength: minLength(formValidationParams.address.minLength),
        maxLength: maxLength(formValidationParams.address.maxLength)
      },
      city: {
        required,
        TWO_TO_SIXTY_LETTERS_WITHOUT_DIGITS_AND_SOME_SPECIAL_CHARACTERS,
        minLength: minLength(formValidationParams.city.minLength),
        maxLength: maxLength(formValidationParams.city.maxLength)
      },
      postCode: {
        regex: FOUR_TO_TWELVE_LETTERS_OR_DIGITS_OR_HYPHENS,
        required
      },
      country: {
        required,
        TWO_TO_SIXTY_LETTERS_WITHOUT_DIGITS_AND_SOME_SPECIAL_CHARACTERS,
        minLength: minLength(formValidationParams.country.minLength),
        maxLength: maxLength(formValidationParams.country.maxLength)
      },
      companyName: {
        requiredIf: requiredIf(function () {
          return !this.isPerson
        }),
        FIVE_TO_150_LETTERS_WITHOUT_SOME_SPECIAL_CHARACTERS,
        minLength: minLength(formValidationParams.companyName.minLength),
        maxLength: maxLength(formValidationParams.companyName.maxLength)
      },
      taxNumber: {
        requiredIf: requiredIf(function () {
          return !this.isPerson
        }),
        regex: FIVE_TO_TWENTY_LETTERS_OR_DIGITS_OR_HYPHENS
      },
      groupCode: {
        FIVE_TO_TWENTY_DIGITS,
        minLength: minLength(formValidationParams.groupCode.minLength),
        maxLength: maxLength(formValidationParams.groupCode.maxLength)
      },
      phoneNumber: {
        FIVE_TO_TWENTY_DIGITS_WITH_OPTIONAL_PLUS_SIGN_AT_THE_FRONT,
        minLength: minLength(formValidationParams.phoneNumber.minLength),
        maxLength: maxLength(formValidationParams.phoneNumber.maxLength)
      }
    }
  },
  mounted() {
    if (!this.$store.getters["user/hasAcceptedTerms"]) {
      console.log("User has not accepted the terms! Redirecting..")
      this.$router.push("terms")
    }
    if (!this.$store.getters["user/getEmail"]) {
      console.log("User has not given the amil address! Redirecting..")
      this.$router.push("terms")
    }
  },
  methods: {
    onSubmit: function () {
      if (this.$v.form.$invalid) {
        console.log(
          "RegistrationPage onSubmit clicked, but the form is invalid!"
        )
        return
      }
      this.form = stringNullify(this.form)
      this.submitIsLoading = true
      GuestChargingService.setAccountingInfo(this.form)
        .then((resp) => {
          window.location.href = resp.data.simplePayUrl
        })
        .catch((e) => {
          console.error("RegistrationPage onSubmit error: " + JSON.stringify(e))
          console.error(e)
          this.feedbackSubTitle = this.$i18n.t("errors.defaultError.subTitle", {
            errorMessage: e.message
          })
          this.isFeedbackModalActive = true
        })
        .finally(() => {
          this.submitIsLoading = false
        })
    }
  }
}
</script>
