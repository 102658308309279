<template lang="html">
  <clip-loader loading class="loader" />
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue"

export default {
  name: "LoadingComponent",
  components: {
    ClipLoader
  }
}
</script>
