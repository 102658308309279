<template lang="html">
  <div class="loading-layout">
    <page-header />

    <p class="text text-center">
      {{ loadingtext }}
    </p>
  </div>
</template>

<script>
import config from "../../config"
import PageHeader from "../ui/PageHeader"

export default {
  name: "LoadingLayout",
  components: { PageHeader },
  props: {
    loadingtext: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      config: config
    }
  }
}
</script>
