import Vue from "vue"
import VueI18n from "vue-i18n"
import config from "../config"

// NOTE: import additional locales here, en locale is imported by default:
import "dayjs/locale/hu"
import "dayjs/locale/cs"

var langs = {}

for (let i = 0; i < config.languages.length; i++) {
  const chargingPage = require("./dictionaries/" +
    config.languages[i] +
    "/chargingPage.json")
  const common = require("./dictionaries/" +
    config.languages[i] +
    "/common.json")
  const errors = require("./dictionaries/" +
    config.languages[i] +
    "/errors.json")
  const landingPage = require("./dictionaries/" +
    config.languages[i] +
    "/landingPage.json")
  const registrationPage = require("./dictionaries/" +
    config.languages[i] +
    "/registrationPage.json")
  const termsPage = require("./dictionaries/" +
    config.languages[i] +
    "/termsPage.json")

  let lang = {
    ...chargingPage,
    ...common,
    ...errors,
    ...landingPage,
    ...registrationPage,
    ...termsPage
  }
  langs[config.languages[i]] = lang
}

Vue.use(VueI18n)

// default
var locale = config.defaultLanguage

export default new VueI18n({
  locale: locale, // set locale
  messages: langs // set locale langs
})
