<template lang="html">
  <button
    class="btn"
    :class="{
      'btn-disabled': disabled,
      'btn-block': block,
      'btn-primary': primary,
      'btn-loading': loading,
      'btn-md': md
    }"
    :type="type"
  >
    <clip-loader v-if="loading" loading class="loader" size="14px" />
    <slot />
  </button>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue"

export default {
  name: "ButtonComponent",
  components: {
    ClipLoader
  },
  props: {
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    md: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "button"
    }
  }
}
</script>
