import { FeatureState } from "../models/models"
import { GuestChargingService } from "@/services/guest-charging-service"

export default {
  namespaced: true,
  state: {
    registrationDocuments: [],
    registrationDocumentsState: FeatureState.Initial,
    dynamicConfig: undefined,
    dynamicConfigState: FeatureState.Initial
  },
  getters: {
    getRegistrationDocuments: (state) =>
      state.registrationDocuments.filter((document) => !document.isOptional),
    getTermsDataIsLoading: (state) =>
      state.registrationDocumentsState === FeatureState.Loading,
    getDynamicConfig: (state) => state.dynamicConfig,
    getDynamicConfigIsLoading: (state) =>
      state.dynamicConfigState === FeatureState.Loading
  },
  mutations: {
    SET_REGISTRATION_DOCUMENTS(state, data) {
      state.registrationDocuments = data
    },
    SET_REGISTRATION_DOCUMENTS_STATE(state, data) {
      state.registrationDocumentsState = data
    },
    SET_DYNAMIC_CONFIG(state, data) {
      state.dynamicConfig = data
    },
    SET_DYNAMIC_CONFIG_STATE(state, data) {
      state.dynamicConfigState = data
    }
  },
  actions: {
    fetchRegistrationDocuments: async ({ commit }, params) => {
      try {
        commit("SET_REGISTRATION_DOCUMENTS_STATE", FeatureState.Loading)

        const { data } = await GuestChargingService.getRegistrationDocuments(
          params
        )

        commit("SET_REGISTRATION_DOCUMENTS", data)
        commit("SET_REGISTRATION_DOCUMENTS_STATE", FeatureState.Success)
      } catch (error) {
        commit("SET_REGISTRATION_DOCUMENTS_STATE", FeatureState.Error)
        console.error(error)
      }
    },

    fetchDynamicConfig: async ({ commit }) => {
      try {
        commit("SET_DYNAMIC_CONFIG_STATE", FeatureState.Loading)

        const { data } = await GuestChargingService.getConfig()

        commit("SET_DYNAMIC_CONFIG", data)
        commit("SET_DYNAMIC_CONFIG_STATE", FeatureState.Success)
      } catch (error) {
        commit("SET_DYNAMIC_CONFIG_STATE", FeatureState.Error)
        console.error(error)
      }
    }
  }
}
