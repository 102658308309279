<template lang="html">
  <div>
    <connector-component
      v-for="connector in connectors"
      :key="connector.id"
      :connector="connector"
      @clicked="onClickChild"
    />
  </div>
</template>

<script>
import ConnectorComponent from "@/components/ui/ConnectorComponent"
export default {
  name: "ConnectorsComponent",
  components: {
    "connector-component": ConnectorComponent
  },
  props: {
    connectors: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onClickChild(obj) {
      this.$emit("connectorselected", obj)
    }
  }
}
</script>
