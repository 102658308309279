import Vue from "vue"
import App from "./App.vue"
import { VueReCaptcha } from "vue-recaptcha-v3"
import i18n from "./i18n"
import router from "./router"
import store from "./store"
import "./filters/dateFilter"

import "./assets/scss/style.scss"

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_CLIENT_CODE })

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
  i18n,
  store,
  router
}).$mount("#app")

function appHeight() {
  const doc = document.documentElement
  doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px")
}

window.addEventListener("resize", appHeight)
appHeight()
