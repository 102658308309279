<template lang="html">
  <div class="language-selector">
    <h2 class="language-selector__title">
      {{ $t("common.lang.langSelection", "en") }}
    </h2>
    <div class="language-selector__items">
      <a
        v-for="(lang, index) in languages"
        :key="index"
        class="language-selector__item"
        href="#"
        @click.prevent="changeLang(lang)"
      >
        <picture v-if="selected === lang" class="language-selector__status">
          <source
            srcset="@/assets/images/lang-select-checked-dark.svg"
            media="(prefers-color-scheme: dark)"
          />
          <img src="@/assets/images/lang-select-checked.svg" alt="" />
        </picture>
        <picture v-else class="language-selector__status">
          <source
            srcset="@/assets/images/lang-select-unchecked-dark.svg"
            media="(prefers-color-scheme: dark)"
          />
          <img src="@/assets/images/lang-select-unchecked.svg" alt="" />
        </picture>
        <div>
          <img
            class="language-selector__flag"
            :src="`${config.imagesPath}/flag-${lang}.svg`"
            alt=""
          />
          <span class="language-selector__language">
            {{ $t("common.lang." + lang) }}
          </span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import config from "../../config"

export default {
  data() {
    return {
      languages: this.$i18n.availableLocales,
      config: config,
      selected: this.$i18n.locale
    }
  },
  watch: {
    "$i18n.locale"(to) {
      this.selected = to
    }
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang
      this.selected = lang
    }
  }
}
</script>
