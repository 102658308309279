<template lang="html">
  <div class="signup-success">
    <page-header />

    <div class="signup-success__image-wrapper">
      <img
        class="signup-success__image"
        src="@/assets/images/email.svg"
        alt=""
      />
    </div>

    <main class="signup-success__main">
      <h1 class="signup-success__title">
        {{ $t("registrationPage.success.title") }}
      </h1>
      <p class="text">
        {{ $t("registrationPage.success.subTitle") }}
      </p>
    </main>
  </div>
</template>

<script>
import config from "../config"
import PageHeader from "../components/ui/PageHeader"

export default {
  name: "CardRegistrationResultPage",
  components: { PageHeader },
  data() {
    return {
      config: config
    }
  }
}
</script>
