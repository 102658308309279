import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import Vue from "vue"

dayjs.extend(localizedFormat)

const dateFormatMap = {
  date: "L",
  datetime: "L LT"
}

Vue.filter("formatDate", function (value, format = dateFormatMap.date) {
  return dayjs(value).format(format)
})
