export const CHARGING_STATUS = Object.freeze({
  NEW: "NEW",
  CREDIT_CARD_VALIDATED: "CREDIT_CARD_VALIDATED",
  STARTED: "STARTED",
  STOPPED: "STOPPED",
  INVALID: "INVALID",
  PENDING: "PENDING",
  START_COMMAND_ACCEPTED: "START_COMMAND_ACCEPTED"
})

export const FeatureState = Object.freeze({
  Initial: "Initial",
  Loading: "Loading",
  Success: "Success",
  Error: "Error"
})

export const chargerTypes = Object.freeze({
  schuko: {
    name: "Schuko"
  },
  cee: {
    name: "CEE"
  },
  type2: {
    name: "Type2"
  },
  ccs: {
    name: "CCS"
  },
  chademo: {
    name: "CHADEMO"
  },
  tesla: {
    name: "Tesla"
  }
})

export const chargerTypesFromBackend = Object.freeze({
  DOMESTIC_A: "schuko",
  DOMESTIC_B: "schuko",
  DOMESTIC_C: "schuko",
  DOMESTIC_D: "schuko",
  DOMESTIC_E: "schuko",
  DOMESTIC_F: "schuko",
  DOMESTIC_G: "schuko",
  DOMESTIC_H: "schuko",
  DOMESTIC_I: "schuko",
  DOMESTIC_J: "schuko",
  DOMESTIC_K: "schuko",
  DOMESTIC_L: "schuko",

  IEC_60309_2_single_16: "cee",
  IEC_60309_2_three_16: "cee",
  IEC_60309_2_three_32: "cee",
  IEC_60309_2_three_64: "cee",

  IEC_62196_T1: "type2",
  IEC_62196_T2: "type2",
  IEC_62196_T3A: "type2",
  IEC_62196_T3C: "type2",

  IEC_62196_T1_COMBO: "ccs",
  IEC_62196_T2_COMBO: "ccs",

  CHADEMO: "chademo",

  TESLA_R: "tesla",
  TESLA_S: "tesla"
})
