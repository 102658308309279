<template>
  <div id="main">
    <router-view />
    <cookie-law
      v-if="config.featureCookieBar"
      position="top"
      transition-name="fade"
    >
      <div slot-scope="props" class="cookie-bar">
        <div class="cookie-bar__top">
          <img src="@/assets/images/cookie.png" />
          <div class="cookie-bar__text">
            <p class="cookie-bar__description">
              {{ $t("common.cookies.text") }}
            </p>
          </div>
        </div>
        <div class="cookie-bar__bottom">
          <a
            :href="cookiesDetailsUrl"
            target="_blank"
            class="cookie-bar__learn-more-link"
          >
            {{ $t("common.cookies.detailBtn") }}
          </a>
          <button-component class="btn-cookie" md @click.native="props.accept">
            {{ $t("common.cookies.acceptBtn") }}
          </button-component>
        </div>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law"
import config from "./config"
import ButtonComponent from "./components/ui/ButtonComponent"
import dayjs from "dayjs"

export default {
  name: "App",
  components: {
    CookieLaw,
    ButtonComponent
  },
  data: function () {
    return {
      cookiesDetailsUrl: config.links.cookie,
      config: config
    }
  },
  watch: {
    "$i18n.locale"(to) {
      localStorage.setItem("guest-charging_locale", to)

      dayjs.locale(to)
    }
  },
  created() {
    this.$store.dispatch("dictionary/fetchDynamicConfig")

    const langFromUrl = this.$route.query.lang
    const langFromLocaleStorage = localStorage.getItem("guest-charging_locale")
    const langFromConfig = config.defaultLanguage

    const languageToSet = langFromUrl || langFromLocaleStorage || langFromConfig

    if (config.languages.includes(languageToSet)) {
      this.$i18n.locale = languageToSet
      dayjs.locale(languageToSet)
    }
  },
  mounted() {
    this.recaptcha()
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded()
      const recaptcha = this.$recaptchaInstance
      recaptcha.hideBadge()
    }
  }
}
</script>
