import axios from "axios"

/**
 * Axios initializing
 */

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000
})

/**
 * Interceptors
 */

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    return Promise.reject(error)
  }
)

/**
 * Send request
 */

function send({
  method,
  url,
  data,
  responseType = "json",
  headers = {},
  params
}) {
  const config = {
    method,
    url,
    data,
    params,
    headers,
    responseType
  }

  return axiosInstance
    .request(config)
    .then((response) => {
      return { data: response.data, status: response.status }
    })
    .catch((error) => Promise.reject(error))
}

/**
 * ApiService
 */

const ApiService = {
  get: (url, params, headers = {}) => {
    return send({
      method: "GET",
      url,
      params,
      headers
    })
  },

  delete: (url) => {
    return send({
      method: "DELETE",
      url
    })
  },

  post: (url, data, headers = {}) => {
    return send({
      method: "POST",
      url,
      data,
      headers
    })
  },

  put: (url, data) => {
    return send({
      method: "PUT",
      url,
      data
    })
  },

  patch: (url, data) => {
    return send({
      method: "PATCH",
      url,
      data
    })
  },

  getBlob: (url) => {
    return send({
      method: "GET",
      url,
      responseType: "blob"
    })
  },

  postFormData: (url, data) => {
    return send({
      method: "POST",
      url,
      data,
      headers: { "Content-Type": "multipart/form-data" }
    })
  }
}

export default ApiService
