<template>
  <div class="landing">
    <div class="landing__content">
      <page-header :header-class="'header--landing'">
        <h1 v-if="!clickedNext" class="landing-header__title">
          {{ $t("landingPage.greetings.title") }}
          <br />
          {{ $t("landingPage.greetings.subTitle") }}
        </h1>
      </page-header>
      <main class="landing-main">
        <picture class="landing-car-image">
          <source
            :srcset="`${config.imagesPath}/main-image-light.png`"
            media="(prefers-color-scheme: dark)"
          />
          <img :src="`${config.imagesPath}/main-image.png`" alt="" />
        </picture>

        <template v-if="clickedNext">
          <h1 class="landing-main__title">
            {{ $t("landingPage.greetings.title") }}
            <br />
            {{ $t("landingPage.greetings.subTitle") }}
          </h1>
          <p class="text">
            {{ $t("landingPage.greetings.shouldUseApp") }}
          </p>
          <a v-if="isMobile" class="block" :href="appDownloadLink">
            <img
              v-if="isIOS"
              class="landing-main__store-btn"
              :src="appleStoreImage"
              alt=""
            />
            <img
              v-if="isAndroid"
              class="landing-main__store-btn"
              src="@/assets/images/download-play-store.svg"
              alt=""
            />
          </a>
        </template>
        <template v-else>
          <loading-component v-if="!dynamicConfig" />
          <charging-prices
            v-if="dynamicConfig && dynamicConfig.isPriceInfoVisible"
            class="landing__charging-prices"
            :ac-charger-base-of-calculation="
              dynamicConfig.acChargerBaseOfCalculation
            "
            :ac-charger-price="dynamicConfig.acChargerPrice"
            :currency="dynamicConfig.currency"
            :dc-charger-base-of-calculation="
              dynamicConfig.dcChargerBaseOfCalculation
            "
            :dc-charger-price="dynamicConfig.dcChargerPrice"
            :prepay-amount="dynamicConfig.prepayAmount"
            :rapid-charger-base-of-calculation="
              dynamicConfig.rapidChargerBaseOfCalculation
            "
            :rapid-charger-price="dynamicConfig.rapidChargerPrice"
            :valid-from="dynamicConfig.validFrom"
            :hide-registration-charge="
              config.featureGuestChargingIsComissionBased
            "
            :guest-commission="dynamicConfig.guestCommission"
            :registration-charge="dynamicConfig.registrationCharge"
          />
          <language-picker />
        </template>
      </main>
    </div>

    <div class="landing__button-container">
      <button-component block primary @click.native="handleNextButtonClick">
        <template v-if="!clickedNext">
          {{ $t("landingPage.next") }}
        </template>
        <template v-else>
          {{ $t("landingPage.nextInsteadOfDownload") }}
        </template>
      </button-component>
    </div>

    <confirm-modal
      v-model="isConfirmModalActive"
      yes-button-color-class="btn-download"
      :main-title="$t('landingPage.confirm.appDownload.mainTitle')"
      :sub-title="$t('landingPage.confirm.appDownload.subTitle')"
      :yes-title="$t('landingPage.confirm.appDownload.yesTitle')"
      :no-title="$t('landingPage.confirm.appDownload.noTitle')"
      @confirm="confirmNext"
      @cancel="navigateToAppDownload"
    />
  </div>
</template>

<script>
import LanguagePicker from "@/components/ui/LanguagePicker"
import ConfirmModal from "@/components/ui/ConfirmModal"
import config from "../config"
import { isAndroid, isIOS, isMobile } from "mobile-device-detect"
import PageHeader from "../components/ui/PageHeader"
import ButtonComponent from "../components/ui/ButtonComponent"
import appleStoreImageHu from "@/assets/logos/apple_store_badge_web_generic_hu.svg"
import appleStoreImageEn from "@/assets/logos/apple_store_badge_web_generic_en.svg"
import { mapGetters } from "vuex"
import ChargingPrices from "../components/ui/ChargingPrices"
import LoadingComponent from "../components/ui/LoadingComponent"

export default {
  name: "LandingPage",
  components: {
    PageHeader,
    "language-picker": LanguagePicker,
    "confirm-modal": ConfirmModal,
    ButtonComponent,
    ChargingPrices,
    LoadingComponent
  },
  props: {},
  data: function () {
    return {
      isConfirmModalActive: false,
      clickedNext: false,
      config: config,
      isMobile: isMobile,
      isIOS: isIOS,
      isAndroid: isAndroid,
      appleStoreImageHu,
      appleStoreImageEn
    }
  },
  computed: {
    appDownloadLink: function () {
      if (this.isIOS) {
        return config.links.app_ios
      }
      if (this.isAndroid) {
        return config.links.app_android
      }
      return ""
    },
    appleStoreImage: function () {
      return this.$i18n.locale === "hu"
        ? this.appleStoreImageHu
        : this.appleStoreImageEn
    },
    ...mapGetters({
      dynamicConfig: "dictionary/getDynamicConfig"
    })
  },
  methods: {
    next: function () {
      if (
        (this.config.links.app_ios.length > 0 ||
          this.config.links.app_android.length > 0) &&
        this.isMobile
      ) {
        this.clickedNext = true
      } else {
        this.confirmNext()
      }
    },
    nextInsteadOfDownload: function () {
      this.isConfirmModalActive = true
    },
    handleNextButtonClick: function () {
      if (this.clickedNext) {
        this.nextInsteadOfDownload()
      } else {
        this.next()
      }
    },
    confirmNext: function () {
      this.$store.dispatch("user/setSeenLandingPage", { status: true })
      this.$router.push("terms")
    },
    navigateToAppDownload: function () {
      window.location.href = this.appDownloadLink
    }
  }
}
</script>
