import Vue from "vue"
import Router from "vue-router"
import LandingPage from "@/pages/LandingPage"
import RegistrationPage from "@/pages/RegistrationPage"
import TermsPage from "@/pages/TermsPage"
import ErrorPage404 from "@/pages/ErrorPage404"
import ErrorPage401 from "@/pages/ErrorPage401"
import ChargingPage from "@/pages/ChargingPage"
import CardRegistrationResultPage from "@/pages/CardRegistrationResultPage"

Vue.use(Router)

export default new Router({
  base: process.env.NODE_ENV === "production" ? "/gc" : "/",
  mode: "history",
  routes: [
    {
      path: "/",
      name: "LandingPage",
      component: LandingPage
    },
    {
      path: "/registration",
      name: "RegistrationPage",
      component: RegistrationPage
    },
    {
      path: "/card-registration-success",
      name: "CardRegistrationResultPage",
      component: CardRegistrationResultPage
    },
    {
      path: "/terms",
      name: "TermsPage",
      component: TermsPage
    },
    {
      path: "/charging/:temporaryCode",
      name: "ChargingPage",
      component: ChargingPage,
      props: true
    },
    {
      path: "/401",
      name: "ErrorPage401",
      component: ErrorPage401
    },
    {
      path: "/*",
      name: "ErrorPage404",
      component: ErrorPage404
    }
  ]
})
