<template lang="html">
  <div v-if="value" class="modal-bg">
    <div class="modal modal--feedback">
      <img class="modal-icon" :src="iconSrc" alt="" />
      <p class="modal-title">
        {{ mainTitle }}
      </p>
      <p class="modal-text text">
        {{ subTitle }}
      </p>
      <button-component md primary @click.native="onConfirm">
        {{ okTitle }}
      </button-component>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "./ButtonComponent"
import errorIcon from "@/assets/images/modal-icon-times-circle.svg"
import successIcon from "@/assets/images/modal-icon-check-circle.svg"

export default {
  name: "FeedbackModal",
  components: {
    ButtonComponent
  },
  props: {
    mainTitle: {
      type: String,
      default: "Are you sure?"
    },
    subTitle: {
      type: String,
      default: "Are you sure?"
    },
    okTitle: {
      type: String,
      default: "OK"
    },
    success: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errorIcon,
      successIcon
    }
  },
  computed: {
    iconSrc() {
      return this.success ? this.successIcon : this.errorIcon
    }
  },
  methods: {
    onConfirm: function () {
      this.$emit("input", false)
      this.$emit("confirm")
    }
  }
}
</script>
