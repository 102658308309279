<template lang="html">
  <div class="charging-layout">
    <page-header :header-class="'header--charging'" />

    <main class="charging__main">
      <div class="charging__card charging__card--location">
        <div>
          <p>{{ selectedCharger.connector.evseId }}</p>
          <p>{{ selectedCharger.address }}</p>
        </div>
      </div>

      <div class="charging__card charging__card--type">
        <span class="charging-card__letter">
          {{ selectedCharger.connector.label }}
        </span>
        <div>
          <p class="charging-card__type">
            {{ connectorTypeName }}
          </p>
          <div class="charging-card__info charger__info">
            <img
              v-if="selectedCharger.connector.mode === 'DC'"
              class="charger__info-icon"
              src="@/assets/images/charger-dc-icon.svg"
              alt=""
            />
            <img
              v-else
              class="charger__info-icon"
              src="@/assets/images/charger-ac-icon.svg"
              alt=""
            />
            <span class="charger__info-text"
              >{{ price }} | {{ selectedCharger.connector.voltage }} V |
              {{ selectedCharger.connector.current }} A</span
            >
          </div>
          <div class="charging-card__power charger__power">
            <img
              class="charger__power-icon"
              src="@/assets/images/charger-power.svg"
              alt=""
            />
            <span class="charger__power-text"
              >{{ selectedCharger.connector.power / 1000 }} kW</span
            >
          </div>
          <p class="charging-card__price">
            {{ price }}
            <img
              class="charging-card__price-icon"
              src="@/assets/images/info-circle-pricing.svg"
              alt=""
            />
          </p>
        </div>
        <img
          :src="`${config.imagesPath}/charger-${connectorTypeSlug}.svg`"
          alt=""
        />
      </div>

      <div class="charging-time">
        <div class="charging__card charging__card--time">
          <p>{{ $t("chargingPage.chargingLayout.chargingStarted") }}</p>
          <p>
            {{ displayTime(chargingStartedAt) }}
          </p>
        </div>
        <div v-if="endTime" class="charging__card charging__card--time">
          <p>{{ $t("chargingPage.chargingLayout.chargingFinished") }}</p>
          <p>{{ displayTime(endTime) }}</p>
        </div>
      </div>

      <div class="charging__card charging__card--progress">
        <p class="charging__card--text">
          {{ $t("chargingPage.chargingLayout.subTitle") }}
        </p>
      </div>

      <div class="charging-info">
        <img src="@/assets/images/info-circle-charging.svg" alt="" />
        <p>{{ $t(chargingInfo) }}</p>
      </div>
    </main>

    <div v-if="stopButtonVisible" class="charging-stop">
      <button-component
        block
        :loading="submitIsLoading"
        class="btn-error"
        @click.native="stopClick()"
      >
        {{ $t("chargingPage.chargingLayout.stopButton") }}
      </button-component>
    </div>

    <confirm-modal
      v-model="isConfirmModalActive"
      yes-button-color-class="btn-error"
      :main-title="$t('chargingPage.confirm.stopCharging.mainTitle')"
      :sub-title="$t('chargingPage.confirm.stopCharging.subTitle')"
      :yes-title="$t('chargingPage.confirm.stopCharging.yesTitle')"
      :no-title="$t('chargingPage.confirm.stopCharging.noTitle')"
      @confirm="confirmStopCharge"
    />
    <feedback-modal
      v-model="isFeedbackModalActive"
      :main-title="$t(feedbackStopMainTitle)"
      :sub-title="$t(feedbackStopSubTitle)"
      :success="feedbackStopSuccess"
      @confirm="handleFeedbackModalOkClick"
    />
  </div>
</template>

<script>
import { GuestChargingService } from "@/services/guest-charging-service"
import { PriceService } from "@/services/price-service"
import ConfirmModal from "@/components/ui/ConfirmModal"
import FeedbackModal from "@/components/ui/FeedbackModal"
import config from "../../config"
import PageHeader from "../ui/PageHeader"
import { mapGetters } from "vuex"
import { CHARGING_STATUS } from "@/models/models"
import ButtonComponent from "../ui/ButtonComponent.vue"
import { chargerTypes, chargerTypesFromBackend } from "../../models/models"

export default {
  name: "ChargingLayout",
  components: {
    PageHeader,
    "confirm-modal": ConfirmModal,
    "feedback-modal": FeedbackModal,
    ButtonComponent
  },
  props: {
    selectedCharger: {
      type: Object,
      default: () => ({ address: "" })
    },
    chargingStartedAt: {
      type: Date,
      default: () => new Date()
    }
  },
  data() {
    return {
      chargerId: "",
      feedbackStopMainTitle: "",
      feedbackStopSubTitle: "",
      feedbackStopSuccess: false,
      stopButtonVisible: false,
      config: config,
      pollingIntervalId: null,
      chargingInfo:
        "chargingPage.chargingLayout.chargingInfo.startCommandAccepted",
      isConfirmModalActive: false,
      isFeedbackModalActive: false,
      submitIsLoading: false,
      endTime: null,
      chargerTypes,
      chargerTypesFromBackend
    }
  },
  computed: {
    price() {
      return PriceService.getPriceLabel(
        this.selectedCharger.connector.price,
        this.$i18n
      )
    },
    connectorTypeName() {
      return this.chargerTypes[this.connectorTypeSlug]
        ? this.chargerTypes[this.connectorTypeSlug].name
        : this.chargerTypes.type2.name
    },
    connectorTypeSlug() {
      return (
        (this.selectedCharger &&
          this.chargerTypesFromBackend[this.selectedCharger.connectorType]) ||
        "type2"
      )
    },
    ...mapGetters({
      dynamicConfig: "dictionary/getDynamicConfig"
    })
  },
  mounted() {
    this.getStatus()
    this.pollStatus()
  },
  beforeDestroy() {
    clearInterval(this.pollingIntervalId)
  },
  methods: {
    stopClick() {
      this.isConfirmModalActive = true
    },
    confirmStopCharge() {
      this.endTime = new Date()
      this.submitIsLoading = true
      GuestChargingService.stopCharging(this.selectedCharger.connector.evseId)
        .then(() => {
          this.feedbackStopOk()
          setTimeout(() => {
            this.afterSuccesfulStop()
          }, 5000)
        })
        .catch((e) => {
          console.error(
            "ChargingLayout stopCharging error: " + JSON.stringify(e)
          )
          console.error(e)

          this.feedbackStopError(e)
        })
        .finally(() => {
          this.submitIsLoading = false
        })
    },
    pollStatus() {
      this.pollingIntervalId = setInterval(() => {
        this.getStatus()
      }, 10000)
    },
    getStatus() {
      GuestChargingService.getChargingStatus()
        .then((resp) => {
          switch (resp.data.chargingStatus) {
            case CHARGING_STATUS.STOPPED:
              this.afterSuccesfulStop()
              break

            case CHARGING_STATUS.STARTED:
              this.stopButtonVisible = true
              this.chargingInfo =
                "chargingPage.chargingLayout.chargingInfo.started"
              break

            case CHARGING_STATUS.INVALID:
              this.feedbackStopMainTitle =
                "errors.chargingStatusErrors.invalid.mainTitle"
              this.feedbackStopSubTitle =
                "errors.chargingStatusErrors.invalid.subTitle"
              this.isFeedbackModalActive = true
              break

            case CHARGING_STATUS.PENDING:
              this.chargingInfo =
                "chargingPage.chargingLayout.chargingInfo.pending"
              break

            case CHARGING_STATUS.START_COMMAND_ACCEPTED:
              this.chargingInfo =
                "chargingPage.chargingLayout.chargingInfo.startCommandAccepted"
              break

            default:
              break
          }
        })
        .catch((e) => {
          console.error("ChargingLayout pollStatus error: " + JSON.stringify(e))
          console.error(e)
        })
    },
    feedbackStopOk() {
      console.error("ChargingLayout feedbackStopOk")
      this.feedbackStopSuccess = true
      this.feedbackStopMainTitle =
        "chargingPage.chargingLayout.stopOk.mainTitle"
      this.feedbackStopSubTitle = "chargingPage.chargingLayout.stopOk.subTitle"
      this.isFeedbackModalActive = true
    },
    feedbackStopError(e) {
      console.error("ChargingLayout feedbackStopError")

      this.feedbackStopSuccess = false

      if (e.messageCode === "guestCharging.session.alreadyStopped") {
        this.feedbackStopMainTitle =
          "errors.chargingErrors.alreadyStoppedError.mainTitle"
        this.feedbackStopSubTitle =
          "errors.chargingErrors.alreadyStoppedError.subTitle"
      } else {
        this.feedbackStopMainTitle = "errors.defaultError.mainTitle"
        this.feedbackStopSubTitle = "errors.defaultError.subTitle"
      }

      this.isFeedbackModalActive = true
    },
    afterSuccesfulStop() {
      if (
        this.dynamicConfig.redirectUrl &&
        this.dynamicConfig.redirectUrl !== ""
      ) {
        window.location.href = this.dynamicConfig.redirectUrl
      } else {
        this.$router.push({ name: "LandingPage" })
      }
    },
    handleFeedbackModalOkClick() {
      if (!this.feedbackStopSuccess) {
        this.afterSuccesfulStop()
      }
    },
    displayTime(date) {
      return `${date.getHours().toString().padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`
    }
  }
}
</script>
