<template lang="html">
  <div class="connector-layout">
    <page-header :header-class="'header--start-charging'">
      <h1 class="start-charging__title">
        {{ $t("chargingPage.connectorLayout.title") }}
      </h1>
      <div class="start-charging__info">
        <img
          class="start-charging__icon"
          src="@/assets/images/info-circle.svg"
          alt=""
        />
        <p>
          {{ $t("chargingPage.connectorLayout.subTitle") }}
        </p>
      </div>
    </page-header>
    <main class="start-charging__main">
      <form class="form-group" @submit.prevent="search()">
        <label class="label">
          {{ $t("chargingPage.connectorLayout.plsTypeChargerId") }}
        </label>
        <div class="input-with-icon">
          <input
            v-model="chargerId"
            class="input"
            type="number"
            :placeholder="$t('chargingPage.connectorLayout.chargerId')"
          />
          <picture class="input-icon" @click="search()">
            <source
              srcset="@/assets/images/input-search-dark.svg"
              media="(prefers-color-scheme: dark)"
            />
            <img src="@/assets/images/input-search.svg" alt="" />
          </picture>
        </div>
        <div class="input-info">
          <p>
            {{ address }}
          </p>
        </div>
      </form>

      <form v-if="connectors.length > 0" class="form-group">
        <label class="label">
          {{ $t("chargingPage.connectorLayout.hint") }}
        </label>
        <loading-component v-if="startCallIsLoading" />
        <connectors-component
          v-else
          :connectors="connectors"
          @connectorselected="connectorSelected"
        />
      </form>

      <loading-component v-if="submitIsLoading" />
    </main>
    <confirm-modal
      v-model="isConfirmModalActive"
      yes-button-color-class="btn-primary"
      :main-title="confirmMainTitle"
      :sub-title="$t('chargingPage.confirm.startCharging.subTitle')"
      :yes-title="$t('chargingPage.confirm.startCharging.yesTitle')"
      :no-title="$t('chargingPage.confirm.startCharging.noTitle')"
      @confirm="confirmCharge"
    />
    <feedback-modal
      v-model="isFeedbackModalActive"
      :main-title="$t('errors.connectorErrors.notFoundError.mainTitle')"
      :sub-title="$t('errors.connectorErrors.notFoundError.subTitle')"
      :success="false"
    />
  </div>
</template>

<script>
import { GuestChargingService } from "@/services/guest-charging-service"
import ConfirmModal from "@/components/ui/ConfirmModal"
import FeedbackModal from "@/components/ui/FeedbackModal"
import ConnectorsComponent from "@/components/ui/ConnectorsComponent"
import config from "../../config"
import PageHeader from "../ui/PageHeader"
import LoadingComponent from "../ui/LoadingComponent.vue"

export default {
  name: "ConnectorLayout",
  components: {
    PageHeader,
    "connectors-component": ConnectorsComponent,
    "confirm-modal": ConfirmModal,
    "feedback-modal": FeedbackModal,
    LoadingComponent
  },
  props: {
    startCallIsLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chargerId: "",
      connectors: [],
      selectedConnector: () => ({ address: "" }),
      confirmMainTitle: "",
      address: "",
      config: config,
      isConfirmModalActive: false,
      isFeedbackModalActive: false,
      submitIsLoading: false
    }
  },
  methods: {
    confirmCharge() {
      this.$emit("connectorchanged", {
        connector: this.selectedConnector,
        address: this.address
      })
    },
    connectorSelected(connector) {
      this.selectedConnector = connector
      this.confirmMainTitle = this.$i18n.t(
        "chargingPage.confirm.startCharging.mainTitle",
        { label: this.selectedConnector.label }
      )
      this.isConfirmModalActive = true
    },
    search() {
      this.submitIsLoading = true
      this.connectors = []
      this.address = ""
      GuestChargingService.getChargingStations(this.chargerId)
        .then((resp) => {
          if (resp && resp.data && resp.data) {
            this.address = resp.data.address
            this.connectors = resp.data.connectors
          }
        })
        .catch(() => {
          this.feedbackPopUp()
        })
        .finally(() => {
          this.submitIsLoading = false
        })
    },
    feedbackPopUp: function () {
      this.isFeedbackModalActive = true
    }
  }
}
</script>
