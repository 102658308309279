<template lang="html">
  <div :class="chargingStarted ? 'charging' : 'start-charging'">
    <loading-layout
      v-show="!successfullLoaded"
      :loadingtext="$t('chargingPage.loadingText')"
    />

    <connector-layout
      v-show="successfullLoaded && !connectorSelected"
      :start-call-is-loading="startCallIsLoading"
      @connectorchanged="connectorChanged"
    />

    <charging-layout
      v-if="chargingStarted"
      :selected-charger="charger"
      :charging-started-at="chargingStartedAt"
    />
    <feedback-modal
      v-model="isFeedbackModalActive"
      :main-title="errorModalTitles.main"
      :sub-title="errorModalTitles.sub"
      :success="false"
      @confirm="handleFeedbackModalOkClick"
    />
  </div>
</template>

<script>
import LoadingLayout from "@/components/layout/LoadingLayout"
import ChargingLayout from "@/components/layout/ChargingLayout"
import ConnectorLayout from "@/components/layout/ConnectorLayout"
import FeedbackModal from "@/components/ui/FeedbackModal"
import { GuestChargingService } from "@/services/guest-charging-service"
import { AuthService } from "@/services/auth-service"
import { mapGetters } from "vuex"
import { CHARGING_STATUS } from "@/models/models"

export default {
  name: "ChargingPage",
  components: {
    "loading-layout": LoadingLayout,
    "charging-layout": ChargingLayout,
    "connector-layout": ConnectorLayout,
    "feedback-modal": FeedbackModal
  },
  props: {
    temporaryCode: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      successfullLoaded: false,
      selectedCharger: null,
      charger: null,
      isFeedbackModalActive: false,
      errorModalTitles: {
        main: "",
        sub: ""
      },
      startCallIsLoading: false,
      authenticateData: null
    }
  },
  computed: {
    chargingStarted() {
      if (this.charger) {
        return true
      }
      return false
    },
    connectorSelected() {
      if (this.charger) {
        return true
      }
      return false
    },
    chargingStartedAt() {
      return this.authenticateData && this.authenticateData.chargingStartedAt
        ? new Date(this.authenticateData.chargingStartedAt)
        : undefined
    },
    ...mapGetters({
      dynamicConfig: "dictionary/getDynamicConfig"
    })
  },
  beforeMount() {
    this.$store.dispatch("user/setTempToken", { token: this.temporaryCode })
    this.authenticate()
  },
  methods: {
    connectorChanged(obj) {
      this.selectedCharger = obj
      this.start()
    },
    authenticate() {
      AuthService.getJwtToken(this.temporaryCode)
        .then((resp) => {
          this.authenticateData = resp.data
          this.processChargingInfo(resp)
          this.setLanguagePreference(resp)
        })
        .catch((e) => {
          console.error("authenticate error: " + JSON.stringify(e))
          console.error(e)

          if (
            e.response.data.messageCode ===
            "guestCharging.session.temporaryCodeExpired"
          ) {
            this.errorModalTitles.main = this.$i18n.t(
              "errors.chargingErrors.temporaryCodeExpired.mainTitle"
            )
            this.errorModalTitles.sub = this.$i18n.t(
              "errors.chargingErrors.temporaryCodeExpired.subTitle"
            )
          } else if (
            e.response.data.messageCode ===
            "guestCharging.public.temporaryCodeNotFound"
          ) {
            this.errorModalTitles.main = this.$i18n.t(
              "errors.chargingErrors.temporaryCodeNotFound.mainTitle"
            )
            this.errorModalTitles.sub = this.$i18n.t(
              "errors.chargingErrors.temporaryCodeNotFound.subTitle"
            )
          } else {
            this.errorModalTitles.main = this.$i18n.t(
              "errors.chargingErrors.authError.mainTitle"
            )
            this.errorModalTitles.sub = this.$i18n.t(
              "errors.chargingErrors.authError.subTitle"
            )
          }

          this.isFeedbackModalActive = true
          this.$store.dispatch("user/setTempToken", { token: "" })
          this.$store.dispatch("user/setBearerToken", { token: "" })
        })
    },
    processChargingInfo(response) {
      if (response && response.data) {
        var chargingInfo = response.data.connectorInfo

        switch (response.data.chargingStatus) {
          case CHARGING_STATUS.STARTED:
          case CHARGING_STATUS.PENDING:
          case CHARGING_STATUS.START_COMMAND_ACCEPTED:
            this.selectedChargerTransform(chargingInfo)
            break

          case CHARGING_STATUS.STOPPED:
            this.navigateToRedirectUrl()
            break

          case CHARGING_STATUS.INVALID:
            this.errorModalTitles.main = this.$t(
              "errors.chargingStatusErrors.invalid.mainTitle"
            )
            this.errorModalTitles.sub = this.$t(
              "errors.chargingStatusErrors.invalid.subTitle"
            )
            this.$root.$emit("bv::show::modal", "errorFeedbackModal")
            break

          default:
            break
        }

        this.successfullLoaded = true
      } else {
        throw new Error("processChargingInfo: Response is null!")
      }
    },
    setLanguagePreference(response) {
      if (response && response.data && response.data.languagePreference) {
        this.$i18n.locale = response.data.languagePreference
      }
    },
    selectedChargerTransform(chargingInfo) {
      this.charger = {
        connector: chargingInfo,
        address: chargingInfo.stationAddress
      }
    },
    start() {
      this.startCallIsLoading = true
      GuestChargingService.startCharging(this.selectedCharger.connector.evseId)
        .then(() => {
          this.charger = this.selectedCharger
        })
        .catch((e) => {
          console.error(
            "ChargingPage startCharging error: " + JSON.stringify(e)
          )
          console.error(e)
          this.feedbackStartError()
        })
        .finally(() => {
          this.startCallIsLoading = false
        })
    },
    feedbackStartError() {
      console.error("ChargingPage feedbackStartError")
      this.errorModalTitles.main = this.$t(
        "errors.chargingErrors.startError.mainTitle"
      )
      this.errorModalTitles.sub = this.$t(
        "errors.chargingErrors.startError.subTitle"
      )
      this.isFeedbackModalActive = true
    },
    handleFeedbackModalOkClick() {
      this.navigateToRedirectUrl()
    },
    navigateToRedirectUrl() {
      if (
        this.dynamicConfig.redirectUrl &&
        this.dynamicConfig.redirectUrl !== ""
      ) {
        window.location.href = this.dynamicConfig.redirectUrl
      } else {
        this.$router.push({ name: "LandingPage" })
      }
    }
  }
}
</script>
