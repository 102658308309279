import { getTruthyEnvValue } from "../services/Util"

const config = {
  languages: process.env.VUE_APP_AVAIALABLE_LANGUAGES
    ? process.env.VUE_APP_AVAIALABLE_LANGUAGES.split(",")
    : ["hu", "en"],
  defaultLanguage: process.env.VUE_APP_DEFAULT_LANGUAGE
    ? process.env.VUE_APP_DEFAULT_LANGUAGE
    : "hu",
  links: {
    cookie: process.env.VUE_APP_COOKIE_GDPR_LINK,
    app_android: process.env.VUE_APP_ANDROID_APP_LINK
      ? process.env.VUE_APP_ANDROID_APP_LINK
      : null,
    app_ios: process.env.VUE_APP_IOS_APP_LINK
      ? process.env.VUE_APP_IOS_APP_LINK
      : null
  },
  registrationFields: {
    companyName: getTruthyEnvValue(
      process.env.VUE_APP_FEATURE_COMPANY_NAME_FIELD
    ),
    taxNumber: getTruthyEnvValue(process.env.VUE_APP_FEATURE_TAX_NUMBER_FIELD),
    groupCode: getTruthyEnvValue(process.env.VUE_APP_FEATURE_GROUP_CODE_FIELD),
    lastName: getTruthyEnvValue(process.env.VUE_APP_FEATURE_LAST_NAME_FIELD),
    firstName: getTruthyEnvValue(process.env.VUE_APP_FEATURE_FIRST_NAME_FIELD),
    phoneNumber: getTruthyEnvValue(
      process.env.VUE_APP_FEATURE_PHONE_NUMBER_FIELD
    ),
    country: getTruthyEnvValue(process.env.VUE_APP_FEATURE_COUNTRY_FIELD),
    postCode: getTruthyEnvValue(process.env.VUE_APP_FEATURE_POST_CODE_FIELD),
    city: getTruthyEnvValue(process.env.VUE_APP_FEATURE_CITY_FIELD),
    address: getTruthyEnvValue(process.env.VUE_APP_FEATURE_ADDRESS_FIELD)
  },
  imagesPath: process.env.VUE_APP_IMAGES_PATH
    ? `${process.env.BASE_URL}${process.env.VUE_APP_IMAGES_PATH}`
    : `${process.env.BASE_URL}images`,
  currency: process.env.VUE_APP_FINANCEOPTIONS__CURRENCY
    ? process.env[
        "VUE_APP_SYMBOLMAP_" + process.env.VUE_APP_FINANCEOPTIONS__CURRENCY
      ]
    : "Ft",
  getCurrencyLabel(currency) {
    return process.env[`VUE_APP_SYMBOLMAP_${currency}`]
  },
  priceMinFractionDigits: process.env.VUE_APP_PRICE_MINIMUM_FRACTION_DIGITS
    ? process.env.VUE_APP_PRICE_MINIMUM_FRACTION_DIGITS
    : 0,
  emailPlaceholder: process.env.VUE_APP_EMAIL_PLACEHOLDER,
  featureGuestChargingIsComissionBased: getTruthyEnvValue(
    process.env.VUE_APP_FEATURE_GUEST_CHARGING_IS_COMISSION_BASED
  ),
  featureCookieBar: getTruthyEnvValue(process.env.VUE_APP_FEATURE_COOKIE_BAR)
}

export default config
