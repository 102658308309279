export function stringNullify(obj) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] === "") {
      obj[key] = null
    } else if (
      obj[key] &&
      typeof obj[key] == "object" &&
      !Date.parse(obj[key])
    ) {
      obj[key] = stringNullify(obj[key])
    }
  })
  return obj
}

export function getTruthyEnvValue(value) {
  return value === "false" ? false : true
}
