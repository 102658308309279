import ApiService from "@/services/api"
import store from "@/store"
import i18n from "@/i18n"

export const GuestChargingService = {
  basePath: "/api/v1",
  chargerLastUsageAPI: "https://ocpidev.grapetest.xyz/ocpi-cdr/api/v2", //"%GC_CHARGER_LAST_USAGE_API%",

  generateMobilitiUser: (user, recaptchaToken) => {
    user.recaptchaToken = recaptchaToken
    return ApiService.post(
      `${GuestChargingService.basePath}/guest-charging/generate-mobiliti-user`,
      user
    )
      .then((resp) => {
        return resp
      })
      .catch((e) => {
        throw e
      })
  },

  setAccountingInfo: (accountingInfo) => {
    return ApiService.post(
      `${GuestChargingService.basePath}/guest-charging/accounting-info`,
      accountingInfo,
      GuestChargingService.createAuthHeader()
    )
      .then((resp) => {
        return resp
      })
      .catch((e) => {
        throw e
      })
  },

  getChargingStations: (stationNumber) => {
    return ApiService.get(
      `${GuestChargingService.basePath}/guest-charging/charging-stations/${stationNumber}`,
      {},
      GuestChargingService.createAuthHeader()
    )
      .then((resp) => {
        return resp
      })
      .catch((e) => {
        throw e
      })
  },

  getChargingProcess: () => {
    return ApiService.get(
      `${GuestChargingService.basePath}/guest-charging/guest-charging-init-data`,
      {},
      GuestChargingService.createAuthHeader()
    )
      .then((resp) => {
        return resp
      })
      .catch((e) => {
        throw e
      })
  },

  startCharging: (evseId) => {
    return ApiService.post(
      `${GuestChargingService.basePath}/guest-charging/start`,
      { evseId: evseId },
      GuestChargingService.createAuthHeader()
    )
      .then((resp) => {
        return resp
      })
      .catch((e) => {
        throw e
      })
  },

  stopCharging: (evseId) => {
    return ApiService.post(
      `${GuestChargingService.basePath}/guest-charging/stop`,
      { evseId: evseId },
      GuestChargingService.createAuthHeader()
    )
      .then((resp) => {
        return resp
      })
      .catch((e) => {
        throw e
      })
  },

  getChargingStatus: () => {
    return ApiService.get(
      `${GuestChargingService.basePath}/guest-charging/status`,
      {},
      GuestChargingService.createAuthHeader()
    )
      .then((resp) => {
        return resp
      })
      .catch((e) => {
        throw e
      })
  },

  createAuthHeader: () => {
    const temporaryCode = store.getters["user/getTempToken"]
    const bearerToken = store.getters["user/getBearerToken"]
    var header = {}
    if (temporaryCode) {
      header.temporaryCode = temporaryCode
    }
    if (bearerToken) {
      header.Authorization = `Bearer ${bearerToken}`
    }
    if (i18n.locale) {
      header["Accept-Language"] = i18n.locale
    }
    return header
  },

  getRegistrationDocuments: (params) => {
    return ApiService.get(
      `${GuestChargingService.basePath}/guest-charging/documents/registration`,
      params
    )
      .then((resp) => {
        return resp
      })
      .catch((e) => {
        throw e
      })
  },

  getConfig: () => {
    return ApiService.get(
      `${GuestChargingService.basePath}/guest-charging/config`
    )
  }
}
