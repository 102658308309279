var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn",class:{
    'btn-disabled': _vm.disabled,
    'btn-block': _vm.block,
    'btn-primary': _vm.primary,
    'btn-loading': _vm.loading,
    'btn-md': _vm.md
  },attrs:{"type":_vm.type}},[(_vm.loading)?_c('clip-loader',{staticClass:"loader",attrs:{"loading":"","size":"14px"}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }