export default {
  namespaced: true,
  state: {
    acceptedTerms: false,
    seenLandingPage: false,
    email: "",
    tempToken: "",
    bearerToken: ""
  },
  getters: {
    hasAcceptedTerms: (state) => state.acceptedTerms,
    hasSeenLandingPage: (state) => state.seenLandingPage,
    getEmail: (state) => state.email,
    getTempToken: (state) => state.tempToken,
    getBearerToken: (state) => state.bearerToken
  },
  mutations: {
    SET_ACCEPTEDTERMS(state, status) {
      state.acceptedTerms = status
    },
    SET_SEENLANDINGPAGE(state, status) {
      state.seenLandingPage = status
    },
    SET_EMAIL(state, email) {
      state.email = email
    },
    SET_TEMPTOKEN(state, token) {
      state.tempToken = token
    },
    SET_BEARERTOKEN(state, token) {
      state.bearerToken = token
    }
  },
  actions: {
    setAcceptedTerms({ commit }, obj) {
      commit("SET_ACCEPTEDTERMS", obj.status)
    },
    setSeenLandingPage({ commit }, obj) {
      commit("SET_SEENLANDINGPAGE", obj.status)
    },
    setEmail({ commit }, obj) {
      commit("SET_EMAIL", obj.email)
    },
    setTempToken({ commit }, obj) {
      commit("SET_TEMPTOKEN", obj.token)
    },
    setBearerToken({ commit }, obj) {
      commit("SET_BEARERTOKEN", obj.token)
    }
  }
}
