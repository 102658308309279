<template lang="html">
  <div v-if="value" class="modal-bg">
    <div class="modal modal--prompt">
      <h4 class="modal-title">
        {{ mainTitle }}
      </h4>
      <p class="modal-text text">
        {{ subTitle }}
      </p>
      <button-component
        :class="yesButtonColorClass"
        block
        @click.native="onConfirm"
      >
        {{ yesTitle }}
      </button-component>
      <a class="modal-continue" href="#" @click.prevent="onCancel">
        {{ noTitle }}
      </a>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "./ButtonComponent"

export default {
  name: "ConfirmModal",
  components: {
    ButtonComponent
  },
  props: {
    mainTitle: {
      type: String,
      default: "Are you sure?"
    },
    subTitle: {
      type: String,
      default: "Are you sure?"
    },
    yesTitle: {
      type: String,
      default: "Yes"
    },
    noTitle: {
      type: String,
      default: "No"
    },
    yesButtonColorClass: {
      type: String,
      default: ""
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onConfirm: function () {
      this.$emit("input", false)
      this.$emit("confirm")
    },
    onCancel: function () {
      this.$emit("input", false)
      this.$emit("cancel")
    }
  }
}
</script>
