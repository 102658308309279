<template>
  <header class="header" :class="headerClass">
    <router-link to="/" class="logo__link">
      <img
        v-if="
          headerClass === 'header--start-charging' ||
          headerClass === 'header--charging'
        "
        class="logo header__logo"
        :src="`${config.imagesPath}/logo-light.svg`"
        alt=""
      />
      <picture v-else class="logo">
        <source
          :srcset="`${config.imagesPath}/logo-light.svg`"
          media="(prefers-color-scheme: dark)"
        />
        <img
          :src="`${config.imagesPath}/logo.svg`"
          class="header__logo"
          alt=""
        />
      </picture>
    </router-link>

    <!-- <a
      v-if="headerClass === 'header--charging'"
      class="assistance"
      href="#">
      <img
        src="@/assets/images/assistance.svg"
        alt="">
    </a> -->
    <div class="language-display">
      <div
        class="language-display__display"
        @click="isLanguageSelectorVisible = !isLanguageSelectorVisible"
      >
        <img
          class="language-display__flag"
          :src="`${config.imagesPath}/flag-${selected}.svg`"
          alt=""
        />
        <span class="language-display__country">{{ selected }}</span>
      </div>
      <div v-if="isLanguageSelectorVisible" class="language-selector__items">
        <a
          v-for="(lang, index) in languages"
          :key="index"
          class="language-selector__item"
          href="#"
          @click.prevent="changeLang(lang)"
        >
          <img
            class="language-selector__flag"
            :src="`${config.imagesPath}/flag-${lang}.svg`"
            alt=""
          />
          <span class="language-selector__language">
            {{ $t("common.lang." + lang) }}
          </span>
        </a>
      </div>
    </div>
    <slot />
  </header>
</template>

<script>
import config from "../../config"

export default {
  name: "PageHeader",
  props: {
    headerClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      languages: this.$i18n.availableLocales,
      selected: this.$i18n.locale,
      isLanguageSelectorVisible: false,
      config: config
    }
  },
  watch: {
    "$i18n.locale"(to) {
      this.selected = to
    }
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang
      this.selected = this.$i18n.locale
      this.isLanguageSelectorVisible = false
    }
  }
}
</script>
