<template lang="html">
  <card>
    <h2 class="title">{{ $t("landingPage.chargingPricesCard.title") }}</h2>

    <ul class="prices">
      <li class="price-item">
        <span class="price-title">{{
          $t("landingPage.chargingPricesCard.dcChargerTitle")
        }}</span>
        <span class="price-value"
          >{{ formatPrice(dcChargerPrice) }} {{ currencyLabel }} /
          {{ translatePricingUnit(dcChargerBaseOfCalculation) }}</span
        >
      </li>
      <li class="price-item">
        <span class="price-title">{{
          $t("landingPage.chargingPricesCard.rapidChargerTitle")
        }}</span>
        <span class="price-value"
          >{{ formatPrice(rapidChargerPrice) }} {{ currencyLabel }} /
          {{ translatePricingUnit(rapidChargerBaseOfCalculation) }}</span
        >
      </li>
      <li class="price-item">
        <span class="price-title">{{
          $t("landingPage.chargingPricesCard.acChargerTitle")
        }}</span>
        <span class="price-value"
          >{{ formatPrice(acChargerPrice) }} {{ currencyLabel }} /
          {{ translatePricingUnit(acChargerBaseOfCalculation) }}</span
        >
      </li>
    </ul>

    <div v-if="guestCommission > 0" class="extra-fee">
      +
      <strong>{{ formatPrice(guestCommission) }} {{ currencyLabel }}</strong>
      {{ $t("landingPage.chargingPricesCard.guestCommission") }}
    </div>
    <div
      v-else-if="!hideRegistrationCharge && registrationCharge > 0"
      class="extra-fee"
    >
      +
      <strong>{{ formatPrice(registrationCharge) }} {{ currencyLabel }}</strong>
      {{ $t("landingPage.chargingPricesCard.registrationCharge") }}
    </div>
    <div v-if="typeof prepayAmount === 'number'" class="prepay-amount">
      <span class="price-title">{{
        $t("landingPage.chargingPricesCard.prepayAmountTitle")
      }}</span>
      <span class="price-value"
        >{{ formatPrice(prepayAmount) }} {{ currencyLabel }} /
        {{ $t("landingPage.chargingPricesCard.occasion") }}</span
      >
    </div>
    <div class="additional-text">
      {{
        $t("landingPage.chargingPricesCard.pricesValidFrom", {
          date: Vue.filter("formatDate")(validFrom)
        })
      }}
    </div>
    <div
      v-if="
        (!guestCommission || guestCommission === 0) &&
        !hideRegistrationCharge &&
        registrationCharge > 0
      "
      class="additional-text"
    >
      {{
        $t("landingPage.chargingPricesCard.registrationChargeAdditionalText")
      }}
    </div>
  </card>
</template>

<script>
import Card from "./Card"
import { PriceService } from "@/services/price-service"
import config from "@/config"
import Vue from "vue"

export default {
  name: "ChargingPrices",
  components: {
    Card
  },
  props: {
    acChargerBaseOfCalculation: {
      type: String,
      default: ""
    },
    acChargerPrice: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: ""
    },
    dcChargerBaseOfCalculation: {
      type: String,
      default: ""
    },
    dcChargerPrice: {
      type: Number,
      default: 0
    },
    prepayAmount: {
      type: Number,
      default: undefined
    },
    rapidChargerBaseOfCalculation: {
      type: String,
      default: ""
    },
    rapidChargerPrice: {
      type: Number,
      default: 0
    },
    validFrom: {
      type: String,
      default: ""
    },
    hideRegistrationCharge: {
      type: Boolean,
      default: false
    },
    guestCommission: {
      type: Number,
      default: undefined
    },
    registrationCharge: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      unitTranslation: {
        KWH: "kWh",
        HOUR: "óra",
        MINUTE: "perc"
      },
      Vue
    }
  },
  computed: {
    currencyLabel() {
      return config.getCurrencyLabel(this.currency)
    }
  },
  methods: {
    formatPrice(value) {
      return PriceService.formatPrice(value, this.$i18n)
    },
    translatePricingUnit(unit) {
      return PriceService.translatePricingUnit(unit, this.$i18n)
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}

.price-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.price-value {
  font-weight: 700;
  flex-shrink: 0;
  padding-left: 8px;
}

.price-item {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.prices {
  margin-bottom: 12px;
}

.additional-text {
  font-style: italic;
  font-size: 14px;
}

.prepay-amount {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 16px;
  margin-top: 20px;
}

.extra-fee {
  font-size: 14px;
}
</style>
