import ApiService from "@/services/api"
import store from "@/store"

export const AuthService = {
  basePath: "/api/v1",

  getJwtToken: (temporaryCode) => {
    return ApiService.get(
      `${AuthService.basePath}/guest-charging/guest-charging-init-data`,
      {},
      AuthService.createTempAuthHeader(temporaryCode)
    )
      .then((resp) => {
        const tokenString = resp.data.jwt
        store.dispatch("user/setBearerToken", { token: tokenString })
        return resp
      })
      .catch((e) => {
        store.dispatch("user/setBearerToken", { token: "" })
        throw e
      })
  },

  createTempAuthHeader(temporaryCode) {
    return {
      temporaryCode: temporaryCode
    }
  }
}
