import Vue from "vue"
import Vuex from "vuex"

import userModule from "./user.module.js"
import dictionaryModule from "./dictionary.module.js"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: userModule,
    dictionary: dictionaryModule
  }
})
