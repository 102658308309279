<template>
  <div class="terms">
    <page-header />
    <main class="page-content">
      <h1 class="page-title">
        {{ $t("termsPage.title") }}
      </h1>
      <p class="text">
        {{ $t("termsPage.subTitle") }}
      </p>
      <form @submit.prevent="nextClicked">
        <div class="form-group">
          <loading-component v-if="termsDataIsLoading" />
          <template v-else>
            <template v-if="acceptAllEnabled">
              <label class="radbox">
                <input
                  v-model="acceptAll"
                  class="radbox__input"
                  type="checkbox"
                />
                <span class="radbox__text">{{
                  $t("termsPage.acceptAll")
                }}</span>
              </label>
              <hr class="form-separator" />
            </template>
            <label
              v-for="(document, index) in registrationDocuments"
              :key="index"
              class="radbox"
            >
              <input
                v-model="$v.form[document.documentId].$model"
                class="radbox__input"
                type="checkbox"
              />
              <span class="radbox__text">
                {{ document.prefix }}
                <a :href="document.url" target="_blank">{{
                  document.documentTitle
                }}</a
                >{{ document.suffix }}
                *
                <p
                  v-if="$v.form[document.documentId].$anyError"
                  class="input-error"
                >
                  {{ $t("termsPage.validation.checkbox") }}
                </p>
              </span>
            </label>
          </template>
          <p v-if="dynamicConfig" class="form-error">
            {{
              $t("termsPage.notification1", {
                advanceFee: dynamicConfig.prepayAmount,
                currency: config.currency
              })
            }}
            <br />
            <template v-if="dynamicConfig.guestCommission > 0">
              {{
                $t("termsPage.notification2", {
                  handlingFee: dynamicConfig.guestCommission,
                  currency: config.currency
                })
              }}
            </template>
            <template v-else>
              {{
                $t("termsPage.notificationCardRegistrationCharge", {
                  cardRegistrationChargeFee: dynamicConfig.registrationCharge,
                  currency: config.currency
                })
              }}
            </template>
          </p>
          <input
            v-model="$v.form.email.$model"
            class="input terms__email-input"
            :class="{ error: $v.form.email.$anyError }"
            type="text"
            :placeholder="config.emailPlaceholder"
          />
          <p v-if="$v.form.email.$anyError" class="input-error">
            {{
              $t("termsPage.validation.email", { minLength: 5, maxLength: 60 })
            }}
          </p>
          <p class="text terms__email-text">
            {{ $t("termsPage.emailDescription") }}
          </p>
          <button-component
            block
            primary
            :disabled="$v.form.$invalid || termsDataIsLoading"
            :loading="submitIsLoading"
            @click.prevent.native="nextClicked"
          >
            {{ $t("termsPage.nextButton") }}
          </button-component>
        </div>
      </form>
    </main>
    <feedback-modal
      v-model="isFeedbackModalActive"
      :main-title="
        emailErrorMessageTitle
          ? emailErrorMessageTitle
          : $t('errors.defaultError.mainTitle')
      "
      :sub-title="
        emailErrorMessageSubtitle
          ? emailErrorMessageSubtitle
          : $t('errors.defaultError.subTitle')
      "
      :success="false"
    />
  </div>
</template>

<script>
import FeedbackModal from "@/components/ui/FeedbackModal"
import { GuestChargingService } from "@/services/guest-charging-service"
import { validationMixin } from "vuelidate"
import {
  email,
  maxLength,
  minLength,
  required,
  sameAs
} from "vuelidate/lib/validators"
import config from "../config"
import PageHeader from "../components/ui/PageHeader"
import { mapGetters } from "vuex"
import LoadingComponent from "../components/ui/LoadingComponent.vue"
import ButtonComponent from "../components/ui/ButtonComponent"

export default {
  name: "TermsPage",
  components: {
    PageHeader,
    "feedback-modal": FeedbackModal,
    LoadingComponent,
    ButtonComponent
  },
  mixins: [validationMixin],
  props: {},
  data: function () {
    return {
      form: {
        email: ""
      },
      acceptAll: false,
      recaptchaToken: "",
      emailErrorMessageTitle: "",
      emailErrorMessageSubtitle: "",
      config: config,
      isFeedbackModalActive: false,
      submitIsLoading: false
    }
  },
  computed: {
    acceptAllEnabled() {
      return this.registrationDocumentsIds.length > 1
    },
    registrationDocumentsIds() {
      return this.registrationDocuments.map((document) => document.documentId)
    },
    checkboxes() {
      return Object.fromEntries(
        Object.entries(this.form).filter(([key]) => key !== "email")
      )
    },
    ...mapGetters({
      termsDataIsLoading: "dictionary/getTermsDataIsLoading",
      registrationDocuments: "dictionary/getRegistrationDocuments",
      dynamicConfig: "dictionary/getDynamicConfig"
    })
  },
  validations() {
    const dynamicCheckboxValidations = this.registrationDocumentsIds.reduce(
      (previous, current) => {
        return {
          ...previous,
          [current]: {
            required,
            sameAs: sameAs(() => true)
          }
        }
      },
      {}
    )

    return {
      form: {
        email: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(60),
          email: email
        },
        ...dynamicCheckboxValidations
      }
    }
  },
  watch: {
    acceptAll: function (currentAcceptAllValue) {
      const checkboxValues = Object.values(this.checkboxes)

      const areAllCheckboxValuesEqual = checkboxValues.every(
        (value) => value === checkboxValues[0]
      )

      // If acceptAll get's unchecked and all checkbox values are not equal, acceptAll field was set progmatically in "checkboxes" watcher and not by user
      if (!currentAcceptAllValue && !areAllCheckboxValuesEqual) {
        return
      }

      const dynamicalCheckboxValues = this.registrationDocumentsIds.reduce(
        (previous, current) => {
          return {
            ...previous,
            [current]: this.acceptAll
          }
        },
        {}
      )

      this.form = {
        email: this.form.email,
        ...dynamicalCheckboxValues
      }
    },
    nextButtonDisabled: function (val) {
      if (val) {
        this.nextClass = "btn-disabled"
      } else {
        this.nextClass = "btn"
      }
    },
    registrationDocumentsIds: function (val) {
      const dynamicalCheckboxInitialValues = val.reduce((previous, current) => {
        return {
          ...previous,
          [current]: false
        }
      }, {})

      this.form = {
        email: this.form.email,
        ...dynamicalCheckboxInitialValues
      }
    },
    "$i18n.locale": function (val) {
      this.$store.dispatch("dictionary/fetchRegistrationDocuments", {
        languageCode: val
      })

      this.acceptAll = false
    },
    checkboxes: function (val) {
      const isEveryCheckboxChecked = Object.values(val).every((value) => value)

      if (isEveryCheckboxChecked) {
        this.acceptAll = true
      } else {
        this.acceptAll = false
      }
    }
  },
  created() {
    this.$store.dispatch("dictionary/fetchRegistrationDocuments", {
      languageCode: this.$i18n.locale
    })
  },
  mounted() {
    if (!this.$store.getters["user/hasSeenLandingPage"]) {
      console.log("User has not been landing page! Redirecting..")
      this.$router.push("/")
    }
  },
  methods: {
    nextClicked: async function () {
      await this.recaptcha()
      this.$store.dispatch("user/setAcceptedTerms", { status: true })
      this.$store.dispatch("user/setEmail", { email: this.form.email })

      try {
        this.submitIsLoading = true

        const resp = await GuestChargingService.generateMobilitiUser(
          this.setUser(),
          this.recaptchaToken
        )

        if (!(resp && resp.data && resp.data.temporaryCode)) {
          this.feedbackPopUp()

          return
        }

        this.$store.dispatch("user/setTempToken", {
          token: resp.data.temporaryCode
        })

        // If all reg fields are hidden
        if (Object.values(config.registrationFields).every((value) => !value)) {
          setTimeout(async () => {
            const accountingResp = await GuestChargingService.setAccountingInfo(
              {}
            )

            this.submitIsLoading = false

            window.location.href = accountingResp.data.simplePayUrl
          }, 5000)

          return
        }

        this.submitIsLoading = false

        this.$router.push("registration")
      } catch ({ response }) {
        console.error(response)
        console.error("onSubmit error: ", response.data)

        this.submitIsLoading = false

        if (response.data.errorMessage === "user.emailAlreadyInUse") {
          this.emailErrorMessageTitle = this.$i18n.t(
            "errors.error400.userEmailAlreadyInUseTitle"
          )
          this.emailErrorMessageSubtitle = this.$i18n.t(
            "errors.error400.userEmailAlreadyInUseSubtitle"
          )
        }

        this.feedbackPopUp()
      }
    },
    feedbackPopUp: function () {
      this.isFeedbackModalActive = true
    },
    setUser: function () {
      return {
        languagePreference: this.$i18n.locale,
        isTermsAndConditionsAccepted:
          this.$store.getters["user/hasAcceptedTerms"],
        email: this.$store.getters["user/getEmail"]
      }
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      this.recaptchaToken = await this.$recaptcha("login")
      const recaptcha = this.$recaptchaInstance
      recaptcha.showBadge()
    }
  }
}
</script>
