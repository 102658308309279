<template lang="html">
  <div>
    <page-header />
    <h1 class="page-title">
      {{ $t("errors.error401.mainTitle") }}
    </h1>
    <p class="text">
      {{ $t("errors.error401.subTitle") }}
    </p>
  </div>
</template>

<script>
import PageHeader from "../components/ui/PageHeader"

export default {
  name: "ErrorPage401",
  components: {
    PageHeader
  }
}
</script>
