<template lang="html">
  <div class="wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Card"
}
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 8px;
  box-shadow: 0 -4px 20px rgba(35, 35, 35, 0.2);
  padding: 1rem;
  color: $color-text-base;
}

@media (prefers-color-scheme: dark) {
  .wrapper {
    background-color: $dark-mode-background-color-secondary;
    color: $dark-mode-color-text-base;
  }
}
</style>
