import config from "../config"

export const PriceService = {
  getPriceLabel: (price, i18n) => {
    let formatted = PriceService.formatPrice(price.amountPerUnit, i18n)
    let pricelabel = formatted + " " + price.currency
    let pricingUnit = ""
    if (price.pricingUnit) {
      pricingUnit = PriceService.translatePricingUnit(price.pricingUnit, i18n)
    }
    if (pricingUnit) {
      return pricelabel + " / " + pricingUnit
    }
    return pricelabel
  },

  formatPrice: (price, i18n) => {
    if (typeof price !== "number") {
      return price
    }

    return new Intl.NumberFormat(i18n.locale, {
      style: "decimal",
      minimumFractionDigits: config.priceMinFractionDigits
    }).format(price)
  },

  translatePricingUnit: (pricingUnit, i18n) => {
    switch (pricingUnit) {
      case "KWH":
        return i18n.t("common.pricing.unitKWH")
      case "HOUR":
        return i18n.t("common.pricing.unitHOUR")
      case "MINUTE":
        return i18n.t("common.pricing.unitMINUTE")
      default:
        return ""
    }
  }
}
